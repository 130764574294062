import(/* webpackMode: "eager" */ "/home/front/auto-desk/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.9_react-dom@18.3.1_react@18.3.1_sass@1.77.8/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.9_react-dom@18.3.1_react@18.3.1_sass@1.77.8/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/front/auto-desk/src/components/layouts/BigContactForm/Btn.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/front/auto-desk/src/components/layouts/BigContactForm/Form.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/front/auto-desk/src/components/layouts/Header/ActiveLink.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/front/auto-desk/src/components/shared/HotCars.js");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/imgs/form_car.jpg");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/imgs/managers/andrei.jpg");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/imgs/managers/bogdan.jpg");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/imgs/managers/maksim.jpg");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/svg/arrow.svg");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/svg/bigForm/case.svg");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/svg/bigForm/hexagon.svg");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/svg/fireCar.svg");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/svg/managers/Phone_Manager.svg");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/svg/socials/telega.svg");
;
import(/* webpackMode: "eager" */ "/home/front/auto-desk/src/svg/socials/whatsapp.svg");
